import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import Keycloak from 'keycloak-js'
import {BehaviorSubject, filter, tap} from 'rxjs'

import {environment} from '@env'
import {AuthState} from '@schemes/ui/auth-state'
import {User} from '@schemes/ro/user'
import {PlatformService} from '@services/platform.service'
import {SearchResult} from '@schemes/ro/search-result'

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _keycloak: Keycloak
  private _state$ = new BehaviorSubject<AuthState>(null)
  private _user$ = new BehaviorSubject<User>(null)

  constructor(
    private platformService: PlatformService,
    private httpClient: HttpClient,
  ) {
  }

  get keycloak() {
    return this._keycloak
  }

  get state$() {
    return this._state$.asObservable().pipe(filter(state => state !== null))
  }

  get state() {
    return this._state$.getValue()
  }

  get user$() {
    return this._user$.asObservable().pipe(filter(state => state !== null))
  }

  get user() {
    return this._user$.getValue()
  }

  get authenticated() {
    return this.state === AuthState.LoggedIn
  }

  async initAuth() {
    if (this.platformService.isServer) return

    this._keycloak = new Keycloak(environment.keycloakConfig)

    try {
      const authenticated = await this.keycloak.init({
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
      })

      this._state$.next(authenticated ? AuthState.LoggedIn : AuthState.NotLoggedIn)
    } catch (error) {
      this._state$.next(AuthState.Error)
    }
  }

  getAuthHeaders(): any {
    return this.state === AuthState.LoggedIn ? {Authorization: `Bearer ${this.keycloak.token}`} : null
  }

  getUser() {
    return this.httpClient.get<User>(`${environment.apiUrl}/user/profile`, {headers: this.getAuthHeaders()}).pipe(
      tap(user => this._user$.next(user)),
    )
  }

  createSubscription() {
    return this.httpClient.post<any>(`${environment.apiUrl}/user/checkout-session`, null, {headers: this.getAuthHeaders()})
  }

  createCustomerPortalSession() {
    return this.httpClient.post<any>(`${environment.apiUrl}/user/customer-portal-session`, null, {headers: this.getAuthHeaders()})
  }

  listFiles(params: any) {
    return this.httpClient.get<SearchResult>(`${environment.apiUrl}/user/files`, {headers: this.getAuthHeaders(), params})
  }
}
